<template>
  <Default>
    <div class="container-bg-white container-space-default">
      <h1 class="title"><span class="border-left-line"></span>Hướng dẫn thanh toán</h1>
      <div class="payment-guide-content">
        <p>Việc thanh toán bắt đầu khi bạn chọn Gói Sử Dụng tại trang <a @click="onClickPricing">Nâng cấp Tài khoản</a></p>
<!--        <img class="step-img" :src="urlImageStep0" alt="payment-guide-0">-->
        <h2 class="step-title">Bước 1: Áp dụng Voucher giảm giá (Nếu có)</h2>
        <ul>
          <li>Bấm vào ô “<strong>Voucher giảm giá</strong>"</li>
          <li>Điển mã Voucher và bấm <strong>Áp dụng</strong></li>
          <li>Bạn được giảm giá nếu Voucher hợp lệ</li>
        </ul>
        <img class="step-img" :src="urlImageStep1" alt="payment-guide-1">
        <h2 class="step-title">Bước 2: Thanh toán theo hướng dẫn</h2>
        <ul>
          <li>Chuyển khoản đúng số tiền, tài khoản và ngân hàng nhận.</li>
          <li>Nên sử dụng nút Copy để lệnh chuyển tiền chính xác.</li>
          <li><strong><span class="text-required">Quan trọng</span>: Nội dung chuyển khoản chính xác như hướng dẫn</strong></li>
        </ul>
        <img class="step-img" :src="urlImageStep2" alt="payment-guide-2">
      </div>
    </div>
  </Default>
</template>

<script>
import Default from "@/layout/Default";
import {getUrlGPhotoSize} from "@/helper/GPhotoHelper";
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";

export default {
  name: "PaymentGuideView",
  components: {
    Default
  },
  data() {
    let urlImageStep0 = getUrlGPhotoSize(
      'https://lh3.googleusercontent.com/pw/ACtC-3fIor2WdMO0F1lZCcOv74hdsSkPBbPivn_snYFI5bqLNRF-o5zFpdg6SmKIxT7eqeR4xEYCuYpXbiy0cxRnnQbqAB1W_GhJekaRSGjQWwXzfQPdQVhzCwYCJheHXjTZaIpiUxWoZuGhM495vzizQog9',
      null,
      400
    )
    let urlImageStep1 = getUrlGPhotoSize(
    'https://lh3.googleusercontent.com/pw/AM-JKLWd7i77vaHrHmewNx6d9wTfhWkrmqpr_3qa-ZV78M3Tq9KIMRz2goaZ0IcSs2b5MHMNyXWZjtyTAbph6CDNAtpFbIkxeUShOHv53OUERJbLngXCG5o6SqyuqZkz_W1exzsUJwyxn-QT4SdnebM6UCLC',
      null,
      800
    )
    let urlImageStep2 = getUrlGPhotoSize(
    'https://lh3.googleusercontent.com/pw/AM-JKLUJZ0Zo0g8fXQqBF_3w4DQd_6zw4xo1V8OJrdi2u7deX9fPZgKosxhJTUHVoYaf95VYHERoa8iOEKMHebvP7SX2cRpLeLi1bdyqFACgD7sOYmMBoy4mGDPlrtKYVFX1ymvVDPxS8HU6OcRGMP4drU5Y',
      null,
      800

    )
    console.log(urlImageStep0)
    return {
      urlImageStep0,
      urlImageStep1,
      urlImageStep2,
    }
  },
  methods: {
    async onClickPricing() {
      await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupPricing}`, true)
    },
  }
}
</script>

<style lang="scss" scoped>

  .title {
    font-size: 28px;
  }

  .payment-guide-content {
    padding: 24px;
    font-size: 16px;
    line-height: 24px;

    .step-title {
      font-size: 24px;
      margin-top: 12px;
    }

    .step-img {
      margin: 12px;
      height: 400px;
      width: auto;
    }

    .text-required {
      color: #ee0033;
    }
  }
</style>
